import React from 'react'
import styled from 'styled-components'

const Container = styled.div`
width: 100%;
    height: 90vh;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
`

function Support() {
  return (
    <Container>
        <h2>Developer Contact Information</h2>
        <p>Name: Funakele Brian Zulu</p>
        <p>Personal Email: bfzulu95@gmail.com</p>
        <p>Company Email: support@tapaservice.com</p>
    </Container>
  )
}

export default Support