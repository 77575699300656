import React, { lazy, Suspense, useState, useEffect } from 'react'
import styled from 'styled-components'
import { MdOutlineClose } from "react-icons/md";
import { isAndroid, isIOS, isMobile } from 'react-device-detect'

import { Loading } from '../../Components/loader/loader'
import { Container, Content } from '../services/list/Services'

const Heading = styled.p`
      font-size: 40px;
      font-weight: 800;
`

const Highlighter = styled.span`
  display: inline;
  color: #009dfe;
  font-size: 40px;
      font-weight: 800;
`

const Image = styled.img`
      width: 100%;
      object-fit: contain;
      height: 60vh;
      @media (min-width: 800px) {
        object-fit: cover;
        height: 80vh;
      }
`

const MobileContainer = styled.div`
    border-bottom: 1px solid black;
    @media (min-width: 800px) {
      display: none;
    }
`

const DesktopContainer = styled.div`
    display: none;
    @media (min-width: 800px) {
      display: flex;
      align-items: center;
      width: 90%;
      margin: auto;
      border-bottom: 1px solid black;
    }
`

const TextContainer = styled.div`
      margin: 0 5% 60px;
      @media (min-width: 800px) {
        width: 40%;
    }
`

export const Text = styled.p`
    text-align: center;
    width: 100%;
    border: none;
    font-size: x-small;
    padding: 10px;
    font-weight: 600;
    @media (min-width: 550px) {
            font-size: 12px;
        }
  @media (min-width: 750px) {
          font-size: 14px;
      }
`

const HeaderMobile = () => {
  return (
    <MobileContainer>
        <div className="">
            <Image className='' src='/main-min.png' alt="card-icon" />
        </div>
        <TextContainer>
          <Heading>RETHINKING <Highlighter> THE WAY YOU </Highlighter>  LOOK AFTER  YOUR CAR</Heading>
        </TextContainer>
      </MobileContainer>
  )
}

const HeaderDesktop = () => {
  return (
    <DesktopContainer>
        <TextContainer>
          <Heading>RETHINKING <Highlighter> THE WAY YOU </Highlighter>  LOOK AFTER  YOUR CAR</Heading>
        </TextContainer>
        <div className="">
            <Image className='' src='/main-min.png' alt="card-icon" />
        </div>
      </DesktopContainer>
  )
}

export const FreeTrialAlert = () => {
  const [windowDimensions, setWindowDimensions] = useState(getWindowDimensions());
  const [isPopUpOpen, setIsPopUpOpen] = useState(true)

  function getWindowDimensions() {
    const { innerWidth: width, innerHeight: height } = window;
    return {
      width,
      height
    };
  }

  useEffect(() => {
    function handleResize() {
      setWindowDimensions(getWindowDimensions());
    }

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return (
    <div>
      {
        isPopUpOpen && !isMobile ? (
          <Container>
            <Content>
              <MdOutlineClose style={{ position: 'absolute', top: 2, right: 2 }} color='red' onClick={() => setIsPopUpOpen(false)} cursor={'pointer'}  size={20}/>
              <Text>Free Trial is Available only to the first 50 Registered Service Providers.</Text>
            </Content>
          </Container>
        ): null
      }
    </div>
  )
}

const BelowFold = lazy(() => import(/* webpackChunkName: "below-fold" */ './below-fold'))

function Home() {
  

  return (
    <div >
      {/* <div className="header" data-test="header">
          <div className="header__card">
            <h1 className="header__h1">Rethinking the way you look after your car.</h1>
          </div>
      </div> */}
      {/* <FreeTrialAlert /> */}
      {/* <Container>
      { isPopUpOpen ? <Content>
          <MdOutlineClose style={{ position: 'absolute', top: 2, right: 2 }} color='red' onClick={() => setIsPopUpOpen(false)} cursor={'pointer'}  size={20}/>
          <Text>Free Trial is Available only to the first 50 Registered Service Providers</Text>
      </Content>: null }
      </Container> */}
      <HeaderMobile />
      <HeaderDesktop />
      <Suspense fallback={<Loading loading={true}/>}>
        <BelowFold/>
      </Suspense>
    </div>
  )
}

export default React.memo(Home)
