import React,{ useState, useEffect, useRef, useMemo } from 'react'
import {useSelector, useDispatch} from 'react-redux'
import { useAuth0 } from '@auth0/auth0-react';
import { useLocation, useHistory } from 'react-router-dom/cjs/react-router-dom.min';

import Stepper from '../../../Components/stepper/stepper';
import { useStepper } from '../../../Components/stepper/hook';
import { createProfile } from '../../../Components/service/service.create.slice';
import { uploadFiles } from '../../../Components/fileUploads/upload.slice';
import { fetchPreUploadData } from '../../../Components/fileUploads/preupload.slice';
import useGetFormValuesAndMethods from '../../../utils/form-hook';
import FileUploads from '../../../Components/fileUploads/FileUploads';
import CreateProfileSuccess from './CreateProfileSuccess';
import Form from './forms/car-service';
import { LocationAlertContainer, LocationAlertText } from './RoadsideAssist';
import { Loading } from '../../../Components/loader/loader';
import Consent from './Consent';

const CreateService = () => {
  const {data,fetchError,fetched} = useSelector(state => state.preUpload)
  const { uploaded, uploadFilesError} = useSelector(state => state.uploadFiles)
  const { createProfileError } = useSelector(state => state.createProfile)
  const { metadata } = useSelector(state => state.metadata)
  const { location, loading } = useSelector(state => state.location)
  const { currentStep, setSteps } = useStepper()
  const [formValues,formMethods,formErrors,lengthValidation] = useGetFormValuesAndMethods()
  
  const [btnDisable, setBtnDisable] = useState(true)
  const dispatch = useDispatch()
  
  const { selectedImage, images, video, formData, serviceTiers, phoneNo, address } = formValues
  const { companyName, statement, price } = formData
  const { getAccessTokenSilently, user:{ email } } = useAuth0();
  const { state } = useLocation()
  const history = useHistory()
  let accessToken = useRef()

  useEffect(()=> {
    if (fetched) {
      const form = new FormData();
      const allFiles = [selectedImage,video,...images]
      const mappedFiles = allFiles.filter(Boolean)
      for (let key in data) {
        if (key !== 'upload_url' && key !== 'key') form.append(key, data[key])
      }
      dispatch(uploadFiles(form, mappedFiles, data.upload_url, data.key))      
    } 
  },[fetched])

  useEffect(() => {
    if (uploaded) {
      // set 1 month expiration 
      let today = new Date();
      const exp = today.setMonth(today.getMonth() + 1)

      const imagesS3links = []
      for(let img of images){
        imagesS3links.push(`${data.key}/${img.name}`)
      }
      
      const postData = {
        logo: selectedImage ? `${data.key}/${selectedImage.name}` : null,
        video: video ? `${data.key}/${video.name}`: null,
        email,
        reviewsCount: 0,
        reviewsTotalScore: 0.0,
        rating: 0,
        ratingFilter: 0,
        imagesS3links,
        serviceTiers,
        companyName,
        phoneNo,
        statement,
        address,
        price: Number(price),
        verified: 'false',
        locationSearchField: address.toString().toLowerCase(),
        subscriptionStatus: 'active',
        subscriptionStatusFilter: 'active',
        profileStatus: 'inactive',
        subscriptionTier: state?.selectedPlan.plan,
        ...(state?.selectedPlan?.plan === 'Free' && { trialExpirationTimeStamp: exp }),
        currentSubscriptionTier: '',
        bookingsCount: 0,
        completedLifetimeBookings: 0
      }
      dispatch(createProfile(postData,'Vehicle Maintenance',metadata?.user_id,accessToken.current,location?.textualInfo?.country))  
    }
  },[uploaded])

  useEffect(()=>{
    (createProfileError || uploadFilesError || fetchError) && setBtnDisable(false)
  },[createProfileError,uploadFilesError,fetchError])

  const handleSubmit = async (e) => {
    try {
      e.preventDefault()
    setBtnDisable(true)
    accessToken.current = await getAccessTokenSilently({
      audience: process.env.REACT_APP_AUTH0_AUDIENCE
    })
    if(!video && !images.length && !selectedImage){
      // set 1 month expiration 
      let today = new Date();
      const exp = today.setMonth(today.getMonth() + 1)

      let postData = {
        logo: '',
        video: '',
        email,
        reviewsCount: 0,
        reviewsTotalScore: 0.0,
        rating: 0,
        ratingFilter: 0,
        imagesS3links: [],
        serviceTiers,
        companyName,
        phoneNo,
        statement,
        address,
        price: Number(price),
        verified: 'false',
        locationSearchField: address.toString().toLowerCase(),
        subscriptionStatus: 'active',
        subscriptionStatusFilter: 'active',
        profileStatus: 'inactive',
        subscriptionTier: state?.selectedPlan.plan,
        ...(state?.selectedPlan?.plan === 'Free' && { trialExpirationTimeStamp: exp }),
        currentSubscriptionTier: '',
        bookingsCount: 0,
        completedLifetimeBookings: 0
      }
      dispatch(createProfile(postData,'Vehicle Maintenance',metadata?.user_id,accessToken.current,location?.textualInfo?.country))  
    } else {
        dispatch(fetchPreUploadData(accessToken.current, email))
    }
    } catch (error) {
      console.log(error);
    }
  }

    useEffect(() => {
      const unloadCallback = (event) => {
        event.preventDefault();
        event.returnValue = "";
        return "";
      };

      window.addEventListener("unload", unloadCallback);
      return () => {
        window.removeEventListener("unload", unloadCallback);
      };
    }, []);

    const steps = useMemo(() => {
      return [
        { name: "T's & C's", index: 0 },
        { name: 'Company Details', index: 1 },
        { name: 'Assets Uploads', index: 2 },
        { name: 'Success', index: 3 }
    ]},[])
  
    useEffect(() => {
      setSteps(steps)
    },[])

    useEffect(() => {
      if (!state?.profileType) {
        history.push('/services/create')
      }
    },[])


  return (
        <div>
          {
            loading ? <Loading loading={loading} label={'Fetching Location..'} />:
            !Object.keys(location).length ? <LocationAlertContainer><LocationAlertText>This page needs location access to function properly. To be able to create a Profile you need to allow location access.</LocationAlertText></LocationAlertContainer>:
            <section className='container'  >
            <Stepper steps={steps} currentStep={currentStep} />
            {
              currentStep === 0 ? <Consent />:
                currentStep === 1 ? <Form formValues={formValues} 
                                          formMethods={formMethods} 
                                          formErrors={formErrors} 
                                          lengthValidation={lengthValidation}
                                    /> :
                currentStep === 2 ? <FileUploads  handleSubmit={handleSubmit} 
                                                  btnDisable={btnDisable} 
                                                  heading={'Add Images (workshops, garages etc)'} 
                                                  data-test='file-uploads' 
                                                  setBtnDisable={setBtnDisable} 
                                                  selectedImage={selectedImage}
                                                  video={video}
                                                  images={images}
                                                  formMethods={formMethods}
                                    /> : 
                currentStep === 3 ? <CreateProfileSuccess type='vehicle_maintenance' /> : null
            }              
          </section>
          }
        </div>
  )
}

export default CreateService
