import React, { useRef, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import ReCAPTCHA from "react-google-recaptcha"
import styled from 'styled-components';

import { useStepper } from '../stepper/hook';
import {MdClose} from 'react-icons/md'
import {BsTrash} from 'react-icons/bs'
import { filesUploadReset } from './upload.slice';
import { fetchPreUploadDataReset } from './preupload.slice';
import { updateFiles, resetProgressBar } from './progress.slice';
import { Uploading } from '../loader/loader'
import { FormButton } from '../button/Button'
import { createProfileReset } from '../service/service.create.slice';
import { ImagesDropZone, VideoDropZone } from './DropZones'
import AllFileUploads from './UploadProgressBar'

const ButtonContainer = styled.div`
display: flex;
justify-content: flex-end;
gap: 10px;
padding-right: 35px;
padding-bottom:50px;
text-align: right;
`


const CheckboxContainer = styled.div`
display: flex;
    gap: 5px;
    align-items: center;
    margin-bottom: 20px;
`

function FileUploads({
      handleSubmit, btnDisable, heading, setBtnDisable, 
      selectedImage, images, video, formMethods}
      ) {
    const { handleVidChange, handleImagesChange } = formMethods
    const {fetchError,fetching} = useSelector(state => state.preUpload)
    const {uploading, uploadFilesError} = useSelector(state => state.uploadFiles)
    const {creating, createProfileError, created } = useSelector(state => state.createProfile)
    const {decrementCurrentStep, incrementCurrentStep} = useStepper();
    const [isVisible, setIsVisible] = useState(true)
    const [checked, setIsChecked] = useState(false);
    const [isBackBtnDisabled, setIsBackBtnDisabled] = useState(false)
    const captchaRef = useRef(null)
    const dispatch = useDispatch()

    useEffect(() => {
      const allFiles = [...images, selectedImage, video]
      const mappedFiles = allFiles.filter(Boolean)
      const filesProgressStates = mappedFiles.map(({name}) => ({ name, percentage: 0}))
      dispatch(updateFiles({ Items: filesProgressStates }))
    },[images,video,selectedImage])

    useEffect(() => {
      if (created) {
        incrementCurrentStep()
        dispatch(filesUploadReset())
        dispatch(fetchPreUploadDataReset())
        dispatch(createProfileReset())
        dispatch(resetProgressBar())
        setIsVisible(true)
        } 
    },[created])

    useEffect(() => {
      if (createProfileError || fetchError || uploadFilesError) {
        setIsVisible(true)
      }

    },[createProfileError, fetchError, uploadFilesError])

    // useEffect(() => {
    //   if (checked) {
    //     setIsVisible(true)
    //   }
    // },[checked])

    const deleteUpdate = (name) => {
      const newImages = images.filter(img => img.name !== name)
      handleImagesChange(newImages)
    }

    const submit = async(e) => {
      setIsVisible(false)
      setIsBackBtnDisabled(true)
      handleSubmit(e)
    }

    const handleOnCheck = (e) => {
      const { value } = e.target
      setIsChecked(value)
  }

  return (
      <article className='uploads'>
        <p className='form__h'>{heading}</p>
        <p style={{ fontSize: 'x-small' }}>*Can be Uploaded Later</p>
        <ImagesDropZone handleImagesChange={handleImagesChange} />
        <div className='uploads__imgsblock'>
          {Array.from(images).map((img,idx) => {
            const {name} = img
            return (
              <div key={idx} className='uploads__images'>
                <div className="uploads__container">
                  <img className='uploads__img' src={img ? URL.createObjectURL(img) : ''} alt="workshop_views" /> 
                  <MdClose color='red' size={30} className='uploads__close' cursor='pointer' onClick={() =>deleteUpdate(name)}/>
                </div>
                <h6 style={{ width: '200px'}}>{name}</h6>
              </div>
            )
          })}
        </div>
        <p className='form__h'>Add 1 Promo video(.mp4)</p>
        <p style={{ fontSize: 'x-small' }}>*Can be Uploaded Later</p>
        <VideoDropZone handleVideoChange={handleVidChange} />
          { video &&
          <div className='uploads__video'>
            <video key={URL.createObjectURL(video)} className='uploads__vid'  controls  >
                  <source type="video/mp4" src={URL.createObjectURL(video)}/>
                  <source type="video/webm" src={URL.createObjectURL(video)}/>
                  <source type="video/ogg" src={URL.createObjectURL(video)}/>
                  Video tag not supported
            </video>
            <h6>{video.name}</h6>
            <BsTrash color='black' size={60} className='uploads__vid-close' cursor='pointer' onClick={() =>handleVidChange('')}/>
          </div>
        }
        { (images.length || video || selectedImage) ? <AllFileUploads /> : null }
        <div className="captcha">
          {/* <CheckboxContainer>
                <input type="checkbox" onChange={handleOnCheck} checked={checked} />
                I agree to <a style={{ color: 'blue' }} href="https://tapaservice.com/terms-of-use" rel='noreferrer' target='_blank'>TapAService Terms of Use</a> and Acknowledge I have read the <a style={{ color: 'blue' }} href="https://tapaservice.com/privacy-policy" rel='noreferrer' target='_blank'>Privacy Policy</a>
            </CheckboxContainer> */}
            { isVisible ? <ReCAPTCHA sitekey={process.env.REACT_APP_RECAPTCHA_SITE_KEY}
                                    onChange={() => setBtnDisable(false)}
                                    onExpired={() => setBtnDisable(true)}
                                    ref={captchaRef}
                          />: null }
        </div>
        { fetching ? <Uploading text="fetching metadata" color={'black'}/> : 
          uploading ? <Uploading text="uploading files" color={'black'} /> : 
          creating ? <Uploading text="creating profile" color={'black'}/> : null }
        { uploadFilesError ? <p className='error'>upload files error: {uploadFilesError}</p> : 
          fetchError ? <p className='error'>fetch preupload data error: {fetchError}</p> :
          createProfileError ? <p className='error'>create profile error: {createProfileError.message}</p> : null }
        <ButtonContainer>
            <FormButton borderRadius='5px' 
                        disabled={isBackBtnDisabled}
                        onClick={decrementCurrentStep}>
                          Back
            </FormButton>
            <FormButton disabled={btnDisable} 
                        background='green' 
                        color='white' 
                        border="none"
                        onClick={submit}>
                          {
                            (createProfileError || fetchError || uploadFilesError) ? 'Retry': 'Create Profile'
                          }
            </FormButton>
        </ButtonContainer> 
      </article>
  )
}

export default FileUploads