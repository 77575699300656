import React from 'react';
import styled from 'styled-components';

const Option = styled.p`
    display: flex;
    gap: 10px;
    margin: 20px 0;
    font-size: 16px;
    justify-content: center;
    @media (min-width: 900px) {
    justify-content: flex-start;
  }
`

const Bullet = styled.span`
    color: blue;
`

const Container = styled.div`
display: none;
@media (min-width: 900px) {
    display: block;
    margin-top: 60px;
  }
`

const MobileContainer = styled.div`
@media (min-width: 900px) {
    display: none;
  }
`

export const Highlighter = styled.p`
  display: inline;
  color: #009dfe;
  font-weight: bolder;
  font-size: 1.5rem;
`

const Text = styled.p`
    line-height: 2.6;
    font-size: 16px;
`

const ImageCardText = styled.p`
    font-size: 16px;
    margin: 0 auto 15px;
    line-height: normal;
`

function ImageCardWithText({text,heading,image}) {
    return (
    <div className="card">
        
        <div className="card__text">
        <div className="card__container">
            <h1 className='card__h'>BRINGING <Highlighter>POWER</Highlighter> TO THE CONSUMERS</h1>
            <ImageCardText>{text[0]}</ImageCardText>
            <ImageCardText>{text[1]}</ImageCardText>
            <ImageCardText>{text[2]}</ImageCardText>
            </div>
        </div>
        <div className="card__image">
            <img className='card__img' src={image} alt="card-icon" loading="lazy" />
        </div>
    </div>
    )
}

export const AboutUs = () => {
    return (
    <div className="card">
        
        <div className="card__text">
        <div className="card__container">
            <h1 className='card__h'>ABOUT <Highlighter>US</Highlighter></h1>
            <Text className='card__p'>At <strong style={{ fontSize: 16}}>Tap A Service</strong> we are driven by the passion to simplify car maintenance for everyone. Whether you need to book a car service, schedule routine maintenance or get roadside assistance, we are here to make the process as easy and hassle-free as possible</Text>
            </div>
        </div>
        <div className="card__image">
            <img className='card__img' src='/about.png' alt="card-icon" loading="lazy" />
        </div>
    </div>
    )
}

export function WhoWeAreDesktop() {
    return (
    <Container>
        <div className="card">
            <div className="card__image">
                <img className='card__img' src='/who.png' alt="card-icon" loading="lazy" />
            </div>
            <div className="card__text">
            <div className="card__container">
                <h1 className='card__h'>WHO WE <Highlighter>ARE</Highlighter></h1>
                <Text><strong style={{ fontSize: 16}}>Tap A Service</strong> is an innovative, online-based platform that connects car owners with trusted Service Providers around them. With a few simple clicks, you can book vehicle services like repairs, oil changes, diagnostics and more. We partner with certified technicians, towing companies and other automative professionals to ensure you receive top-quality care when you need it.</Text>
                </div>
            </div>
        </div>
    </Container>
    )
}

export function WhoWeAreMobile() {
    return (
    <MobileContainer>
        <div className="card">
            <div className="card__text">
            <div className="card__container">
                <h1 className='card__h'>WHO WE <Highlighter>ARE</Highlighter></h1>
                <Text><strong style={{ fontSize: 16}}>Tap A Service</strong> is an innovative, online-based platform that connects car owners with trusted Service Providers around them. With a few simple clicks, you can book vehicle services like epairs, oil changes, diagnostics and more. We partner with certified technicians, towing companies and other automative professionals to ensure you receive top-quality care when you need it.</Text>
                </div>
            </div>
            <div className="card__image">
                <img className='card__img' src='/who.png' alt="card-icon" loading="lazy" />
            </div>
        </div>
    </MobileContainer>
    )
}

export function WhatWeOfferDesktop({text,heading,image}) {
    return (
    <Container>
        <div className="card">
            <div className="card__image">
                <img className='card__img' src={image} alt="card-icon" loading="lazy" />
            </div>
            <div className="card__text">
            <div className="card__container">
                <h1 className='card__h'>WHAT WE <Highlighter>OFFER</Highlighter></h1>
                <Option><Bullet>{'>>'}</Bullet> Convenience for our clients. Make a Car Service Booking from your smartphone, anywhere, anytime.</Option>
                <Option><Bullet>{'>>'}</Bullet> More options for our clients. Get access to a wider pool of Car Service and Roadside Assistance Providers.</Option>
                <Option><Bullet>{'>>'}</Bullet> Realtime Roadside Assistance for motorists.</Option>
                <Option><Bullet>{'>>'}</Bullet> Increased visibility for Car Service Providers.</Option>
                <Option><Bullet>{'>>'}</Bullet> Increased Visibity for Roadside Assistance operators.</Option>
                <Option><Bullet>{'>>'}</Bullet> More streamlined Service Provider - Consumer interaction.</Option>
                </div>
            </div>
        </div>
    </Container>
    )
}

export function WhatWeOfferMobile({text,heading,image}) {
    return (
    <MobileContainer>
        <div className="card">
            <div className="card__text">
            <div className="card__container">
                <h1 className='card__h'>WHAT WE <Highlighter>OFFER</Highlighter></h1>
                <Option><Bullet>{'>>'}</Bullet> Convenience for our clients. Make a Car Service Booking from your smartphone, anywhere, anytime.</Option>
                <Option><Bullet>{'>>'}</Bullet> More options for our clients. Get access to a wider pool of Car Service and Roadside Assistance Providers.</Option>
                <Option><Bullet>{'>>'}</Bullet> Realtime Roadside Assistance for motorists.</Option>
                <Option><Bullet>{'>>'}</Bullet> Increased visibility for Car Service Providers.</Option>
                <Option><Bullet>{'>>'}</Bullet> Increased Visibity for Roadside Assistance operators.</Option>
                <Option><Bullet>{'>>'}</Bullet> More streamlined Service Provider - Consumer interaction.</Option>
                </div>
            </div>
            <div className="card__image">
                <img className='card__img' src={image} alt="card-icon" loading="lazy" />
            </div>
        </div>
    </MobileContainer>
    )
}

export default ImageCardWithText;
