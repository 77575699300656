import React, { useState } from 'react';
import styled from 'styled-components';

import PrivacyPolicy from '../../privacy-policy/privacy-policy';
import CookiePolicy from '../../cookie-policy/cookie-policy';
import TermsOfUse from '../../terms/terms-of-use';
import { useStepper } from '../../../Components/stepper/hook'
import { FormButton } from '../../../Components/button/Button';

const Container = styled.div`
    width: 100%;
    height: 80vh;
`

const Wrapper = styled.div`
    width: 90%;
    height: 80%;
    margin: auto;
    overflow: scroll;
    background-color: #f1f1f1;
`

const Bar = styled.div`
    display: flex;
    gap: 10px;
    justify-content: center;
    margin: 30px auto 10px;
`

const CheckboxContainer = styled.div`
    display: flex;
    gap: 5px;
    align-items: center;
    margin-bottom: 20px;
`

const ActionContainer = styled.div`
display: flex;
    justify-content: space-between;
    align-items: center;
    width: 90%;
    margin: 30px auto 0;
`

const Consent = () => {
    const [active, setActive] = useState('privacy')
    const [checked, setIsChecked] = useState(false);
    const {incrementCurrentStep} = useStepper();

    const handleOnCheck = (e) => {
        const { value } = e.target
        setIsChecked(value)
    }

    return (
        <Container>
            <Bar>
                {
                    ['privacy', 'terms', 'cookies'].map((el, idx) => {
                        return <p style={{ cursor: 'pointer' ,...(active === el && { textDecoration: 'underline', fontWeight: 600 })}} onClick={() => setActive(el)} key={idx}>{el.toUpperCase()}</p>
                    })
                }
            </Bar>
            <Wrapper>
                {
                    active === 'privacy' ? <PrivacyPolicy noFooter={true} />: active === 'terms' ? <TermsOfUse noFooter={true} />: <CookiePolicy noFooter={true}/>
                }
                </Wrapper>
            <ActionContainer>
            <CheckboxContainer>
                <input type="checkbox" onChange={handleOnCheck} checked={checked} />
                I agree to TapAService Terms of Use and Acknowledge I have read the Privacy Policy
            </CheckboxContainer>
                <FormButton
                background='#76C3D4' 
                color='white'
                disabled={!checked} 
                onClick={() => incrementCurrentStep()}>
                    Next
                </FormButton>
            </ActionContainer>
        </Container>
    )
}

export default Consent