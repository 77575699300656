import React,{ useEffect, useRef, useContext } from 'react';
import { useDispatch } from 'react-redux';
import { API, graphqlOperation } from 'aws-amplify';
import styled, { css } from 'styled-components';
import { useHistory } from 'react-router-dom'
import { useAuthState } from 'react-firebase-hooks/auth';
import { BiSend } from 'react-icons/bi'
import { MdRefresh } from "react-icons/md";
import { BsFillArrowRightSquareFill, BsArrowLeftCircle } from 'react-icons/bs'

import { createMessage } from '../../graphql/mutations'
import { auth } from '../../firebase/firebase';
import { sendMessageError, sendMessageRequest, sendMessageReset } from './chat.slice';
import Message from '../message/message';
import { OnlineStatus } from '../offline/Context';
import { useListAndCreateMessage } from '../../utils/client-graphql-hooks';
import { Note, Error } from './chat';
import customAxios from '../../redux/axios/axios'

const ChatHeader = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px 16px;
    background: #f9f9f9;
`
const Wrapper = styled.div`
    height: 100%;
    width: 100%;
    display: flex;
    align-items: center;
    gap: 10px;
`

const Image = styled.img`
    width: 40px;
    height: 40px;
    border-radius: 50%;
    object-fit: cover;
`
const ChatName = styled.p`
    font-weight: 600;
    margin-bottom: 4px;
`

const Booking = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    cursor: pointer;
    ${(props) => props.disabled && css`
      cursor: none;
      pointer-events: none;
      opacity: 0.5;
      `
    }`

const Back = styled(BsArrowLeftCircle)`
@media (min-width: 850px) {
    display: none;
  }
`

const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  align-self: center;
  width: 100%;
  height: 100%;
`

const SubContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`

const Alert = styled.p`
    text-align: center;
    padding: 5px;
    background: rgb(118, 195, 212);
    margin-bottom: 1px;
`
                    
export function Chat({ receiver, openChatCreds, setIsChatOpen }) { 
    const [{ email: userEmail, accessToken, displayName }, loading, error] = useAuthState(auth);
    const { messages, messageText, setMessageText, setMessages, messagesRef, todayRef, refetch, isBookingAuthorised } = useListAndCreateMessage(userEmail.concat('_vm'),receiver)
    const online = useContext(OnlineStatus)
    const dispatch = useDispatch()
    const history = useHistory()
    const messagesEndRef = useRef(null)
    const { companyName, logo, address, email } = openChatCreds

    useEffect(() => {
      
      return () => {
        dispatch(sendMessageReset())
      }
    },[])

    useEffect(() => {
        messagesEndRef.current?.scrollIntoView({ behavior: "smooth" })
      },[messages])

    const proceedToBooking = () => {
      history.push('/booking/create',{ companyName, email, address })
    }

    const onSubmit = (e) => {
        e.preventDefault();
        e.stopPropagation();
        if (!online) {
          return alert('Can not send message without internet connection')
        }
        handleMessageSubmit()
    }

    const handleOnChange = (e) => {
        const {value} = e.target
        setMessageText(value)
    }

    const handleMessageSubmit = async() => {
        let today = new Date();
        const codeExp = today.setMonth(today.getMonth() + 2)

        const input = {
          channelID: userEmail.concat('_vm'),
          author: userEmail,
          body: messageText.trim(),
          receiver,
          senderName: displayName,
          dateTime: codeExp/1000
        };

        dispatch(sendMessageRequest({ message: input }))
        const currentMessages = messagesRef.current
        if (todayRef.current) {
          setMessages({...messages,items:[...currentMessages, input]});
          messagesRef.current = [...currentMessages,input]
        } else {
          setMessages({...messages,items:[...currentMessages, { type: 'date', value: 'Today', createdAt: Math.random() }, input], today: true });
          messagesRef.current = [...currentMessages,{ type: 'date', value: 'Today', createdAt: Math.random() }, input]
          todayRef.current = true
        }
         
        try {
          await API.graphql(graphqlOperation(createMessage, { input }, { authToken: accessToken }))
          setMessageText('')
        } catch (error) {
          dispatch(sendMessageError({ message: input }))
        }
      }

  return (
    <div className='messages messages__client-chat'> 
        <ChatHeader>
            <Wrapper>
                <Back size={20} onClick={() => setIsChatOpen(false)} />
                <Image
                    src={`${process.env.REACT_APP_S3_BUCKET_URL}/${logo}`}
                />  
                <ChatName>{companyName}</ChatName>
            </Wrapper>
            <Booking disabled={!isBookingAuthorised} onClick={proceedToBooking} >
                <BsFillArrowRightSquareFill size={15} />
                <p>Booking</p>
            </Booking>
        </ChatHeader>       
      <div style={{ backgroundColor: '#f9f9f9'}} className="messages__container messages__client-chat-container">
        { !isBookingAuthorised && !messages.loading ? <Alert>You need Authorisation Code before you can create a booking</Alert>: null }
        <Alert>Chat messages are deleted on Booking Checkout</Alert>
        <div className="messages__list">
        {
            messages?.items?.length ? <div className="messages__scroller">
            { 
              messages.items.map((message) => <Message key={message.createdAt || message.id} message={message} />) }
            <div ref={messagesEndRef} />
        </div> : (
          <Container>
            { messages.loading ? <Note>loading messages..</Note>:
             messages.err ? <SubContainer>
                              <Error>Error fetching messages</Error>
                              <MdRefresh size={20} cursor={'pointer'} onClick={refetch} />
                            </SubContainer>: 
            !messages.items.length ? <Note>No chat messages</Note>: null }
          </Container>
        )}
        </div>
        <div className="messages__chat">
            <form onSubmit={onSubmit} className='messages__form'>
                <input className='messages__input'
                type="text"
                name="messageBody"
                placeholder="Type your message here"
                onChange={handleOnChange}
                value={messageText}
                />
                <BiSend size={20} onClick={onSubmit} />
            </form>
        </div>
    </div>
  </div>
  )
}