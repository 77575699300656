import { createSlice } from "@reduxjs/toolkit"

const initialState = {
    messages: []
}

const authorizedBookings = {
    channelIDs: {}
}
const message = createSlice({
  name: 'message',
  initialState,
  reducers: {
    sendMessageRequest(state, action) {
        state.messages = [...state.messages, {...action.payload.message, sending: true, sendError: '', sent: false}]
    },
    sendMessageSuccess(state, action) {
        const updatedMessages = state.messages.map(msg => {
            if (msg.body === action.payload.message.body) {
                return {...msg, sending: false, sendError: '', sent: true }
            } else {
                return msg
            }
        })
        state.messages = [...updatedMessages]
    },
    sendMessageError(state, action) {
        const newMessages = state.messages.map(msg => {
            if (msg.body === action.payload.message.body) {
                return {...msg, sending: false, sendError: 'Not Sent', sent: false }
            } else {
                return msg
            }
        })
        state.messages = [...newMessages]
    },
    sendMessageReset(state) {
        state.messages = []
    },
  }
})

export const { sendMessageError, sendMessageRequest, sendMessageReset, sendMessageSuccess } = message.actions

const bookingAuthorisations = createSlice({
    name: 'bookingAuthorisations',
    initialState: authorizedBookings,
    reducers: {
      addBookingAuthorization(state, action) {
            const channelID = action.payload
          state.channelIDs = {...state.channelIDs, [channelID]: true }
      },
      resetBookingAuthorizations(state) {
          state.channelIDs = { }
      },
    }
  })
  
export const { addBookingAuthorization, resetBookingAuthorizations } = bookingAuthorisations.actions
export const bookingAuthorisationsReducer = bookingAuthorisations.reducer

export default message.reducer