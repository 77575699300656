import React, { useState } from 'react'
import styled from 'styled-components'
import {BsArrowClockwise, BsInfoCircle} from 'react-icons/bs'
import {GrCar} from 'react-icons/gr'
import {FiUsers} from 'react-icons/fi'

import { InformativeFeature } from '../../../../Components/feature/feature'
import { InputFieldReq, TextAreaFieldReq, } from '../../../../Components/field/field'
import { ListItemsWithAutoCompletion, ListItems } from '../../../../Components/list/ListItems'
import TagInput from '../../../../Components/tag/tag'
import { FormButton } from '../../../../Components/button/Button'
import { useStepper } from '../../../../Components/stepper/hook'

const ButtonContainer = styled.div`
    padding-right: 35px;
    padding-top: 50px;
    text-align: right;
  }
`

const Tip = styled.p`
  text-align: right;
  color: blue;
`

const AddressLabelContainer = styled.div`
display: flex;
    gap: 5px;
`

const features = [
    { icon: <FiUsers />,text:'With TapAService its the clients that come to you.',heading:'Get clients on the fly' },
    { icon:<BsArrowClockwise />,text:'Around the clock visibility.',heading:'24/7 365 Visibility'},
    { icon:<GrCar />,text:"Easily get in touch with your product consumers.",heading:'Huge customer base'}
  ]

export const minorService = [
    { label: 'Air Filter', value: 'Air Filter' },
    { label: 'Oil Filter', value: 'Oil Filter' },
    { label: 'Fuel Filter', value: 'Fuel Filter' },
    { label: 'Spark Plugs', value: 'Spark plugs' },
    { label: 'Lubricants', value: 'Lubricants' },
    { label: 'Clutch', value: 'Clutch' },
    { label: 'Exhaust', value: 'Exhaust' },
    { label: 'Globes', value: 'Globes' },
    { label: 'Fuses', value: 'Fuses' },
    { label: 'Brake Pads', value: 'Brake Pads' },
    { label: 'Brake Discs', value: 'Brake Discs' },
    { label: 'Shocks', value: 'Shocks' },
    { label: 'Wiper Blades', value: 'Wiper Blades' },
    { label: 'Battery', value: 'Battery' },
    { label: 'Clutch', value: 'Clutch' },
    { label: 'Inspection Checks', value: 'Inspection Checks' },
  ]

export const majorService = [
{ label: 'Dyno', value: 'Dyno' },
{ label: 'Software Tuning', value: 'Software Tuning' },
{ label: 'Transmission', value: 'Transmission' },
{ label: 'Suspension', value: 'Suspension' },
{ label: 'Engine Control Unit', value: 'Engine Control Unit' },
{ label: 'Drive Belts', value: 'Drive Belts' },
{ label: 'Gearbox', value: 'Gearbox' },
{ label: 'Emission report generation', value: 'Emission report generation' },
{ label: 'Paint Jobs', value: 'Paint Jobs' },
{ label: 'Performance Upgrades', value: 'Performance Upgrades' },
{ label: 'Diagnostics', value: 'Diagnostics' },
{ label: 'Mechanical Repairs', value: 'Mechanical Repairs' },
{ label: 'Inspect CV Joints', value: 'Inspect CV Joints' },
{ label: 'Air Conditioning', value: 'Air Conditioning' },
{ label: 'Road Test', value: 'Road Test' },
{ label: 'Diff Oil Levels', value: 'Diff Oil Levels' },
]

function Form({ formValues, formMethods, formErrors, lengthValidation }) {
    const [error, setError] = useState(false)
    const [logoSizeError, setLogoSizeError] = useState(null)
    const { selectedImage, autocomplete, formData, serviceTiers, phoneNo, address } = formValues
    const { companyName, price, statement } = formData
    const {incrementCurrentStep} = useStepper();
    
    const handleItemChange = async (e,idx) => formMethods.handleItemChange(e,idx)
    const handleItemRemove = (e,idx) => formMethods.handleItemRemove(e,idx)
    const handleTagAdd = (e,id,value) => formMethods.handleTagAdd(e,id,value)
    const handleTagUpdate = (e,supplied,id) => formMethods.handleTagUpdate(e,supplied,id)

    const next = () => {
        if (companyName && price && statement && serviceTiers['major'].length &&
            phoneNo[0].length && address[0].length && serviceTiers['minor'].length && 
            !Object.keys(formErrors).length && !Object.keys(lengthValidation).length && !logoSizeError) {
            incrementCurrentStep()
        } else {
            if (Object.keys(formErrors).length || Object.keys(lengthValidation).length) {
                setError('Input Validation Error(s)')
            } else if (logoSizeError) {
                setError('One or more dimensions are less than 225')
            } else {
                setError('Missing Fields!')
            }
        }
    }
    
    const tagsOptions = [
        { 
          data:serviceTiers['minor'],
          label: 'Minor', 
          id:'minor', 
          handleTagAdd, handleTagUpdate, 
          options: minorService,
          required: true,
        },
        {
          data:serviceTiers['major'],
          label: 'Major', 
          id:'major',
          handleTagUpdate, 
          handleTagAdd, 
          placeholder:'Enter options',
          options: majorService,
          required: true,
        }
      ]
  
      const exclusions = [
        {
          data:serviceTiers['exclusions'],
          label: "What you don't do (optional)", 
          id:'exclusions', 
          handleTagAdd, 
          handleTagUpdate,
          options: []
        }
      ]

      const handleImageLoad = (e) => {
        const { naturalHeight, naturalWidth } = e.target;
        if (naturalHeight < 225 || naturalWidth < 225) {
          setLogoSizeError('One or more dimensions are less than 225')
        } else {
          setLogoSizeError(null)
        }
    };

  return (
    <div  className='services__container'>
        <article className='services__root'>
        <div className='services__headings'>
            <h1 className='form__h1'>Create a Service Provider Profile</h1>
            <p className='form__p'>Open your market up and earn easier</p>
        </div>
        <form style={{height:'1104px',overflowY:'scroll'}}
            className='form svc-form' 
            data-test='form' 
            onSubmit={next}
            >
            <p className='form__h'>Fill in the form below</p>
            <div className="form__profile">
                <h4 style={{ fontWeight: 600 }}>Logo</h4>
                <p>(100kB max size; 225x225 minimum dimensions)</p>
                <p style={{ fontSize: 'x-small' }}>Click image below to Edit</p>
                <p style={{ fontSize: 'x-small' }}>*Can Be Uploaded Later</p>
                <div className='form__image'>
                <label htmlFor="image" style={{cursor:'pointer'}}>
                    <img className= 'form__img' 
                        src={selectedImage ? (URL.createObjectURL(selectedImage)): '/add.png'} 
                        alt="logo"
                        style={{ objectFit: 'center' }}
                        onLoad={handleImageLoad}
                    />
                </label>
                <p style={{ color: 'red' }}>{formErrors['logo'] || logoSizeError}</p>
                </div>
                <input style={{display:'none'}} 
                    type="file" 
                    id="image" 
                    name="image" className='form__file'  
                    onChange={formMethods.handleImageChange}  
                />
            </div>
            <div className='form__fields' >
                <InputFieldReq label='Company Name' 
                            value={companyName} 
                            name='companyName' 
                            placeholder='Company Name not more than 25 characters'
                            onChange={formMethods.handleOnChange} 
                />
                <Tip>Company Name can not be changed later</Tip>
                { <p className="form__error">{formErrors['companyName']}</p> }
                <div className='form__item' >
                <label htmlFor="statement" style={{ fontWeight: 600 }}>Statement*:</label>
                <TextAreaFieldReq name='statement' 
                                    className='form__input' 
                                    rows='2' 
                                    value={statement} 
                                    onChange={formMethods.handleOnChange} 
                                    placeholder='Mission statement not more than 100 characters'
                />    
                </div>
                { <p className="form__error">{formErrors['statement']}</p> }
                <div className='form__item' >
                <label htmlFor="address" style={{ fontWeight: 600 }}>Tel No.*:</label>
                <div className="form__address" >
                    <ListItems data={phoneNo} 
                            placeholder={'011XXXXXXXX'} 
                            handleItemAdd={formMethods.handleItemAdd} 
                            name={'phone'}
                            handleItemRemove={handleItemRemove} 
                            handleItemChange={handleItemChange} 
                            rows={1} 
                    />
                </div>
                </div>
                <div className='form__item' >
                <AddressLabelContainer>
                  <label htmlFor="address">Address*:</label>
                  <div className="form__tooltip-icon" title='Select the Address suggested from the autocomplete dropdown.  Entering your own address will result in your profile being excluded from location search results. If your address doesnt show up trying entering the Street without the house number.'>
                          <BsInfoCircle size={20} color='blue' />
                      </div>
                  </AddressLabelContainer>
                <div className="form__address" >
                <ListItemsWithAutoCompletion data={address} 
                                            placeholder={'Please enter your address'} 
                                            handleItemAdd={formMethods.handleItemAdd} 
                                            name={'address'}
                                            handleItemRemove={handleItemRemove} 
                                            handleItemChange={handleItemChange} 
                                            autocomplete={autocomplete} 
                />
                <Tip>Select Address from the dropdown. Never enter your own address.</Tip>
                </div>
                </div>
                <div className='services__block'>
                <p className='form__h'>Service Tiers</p>
                <p>Enter options for each service tier </p>
                </div>
                
                { tagsOptions.map((tag,idx) => {
                    const {name,label,data,value,id,onChange,placeholder,handleTagAdd,handleTagUpdate,options,required} = tag
                    return <TagInput key={idx} 
                                    value={value} 
                                    id={id} 
                                    data={data} 
                                    label={label} 
                                    handleTagUpdate={handleTagUpdate}
                                    name={name} 
                                    placeholder={placeholder} 
                                    handleTagAdd={handleTagAdd} 
                                    handleOnChange={onChange} 
                                    options={options}
                                    required={required}
                            />
                })}
                <div className='services__block'>
                <p className='form__h'>Rate</p>
                    <InputFieldReq label='Rate/hr' 
                                value={price} 
                                name='price' 
                                placeholder='Rate/hr'
                                onChange={formMethods.handleOnChange} 
                    />
                    { <p className="form__error">{formErrors['price']}</p> }
                </div>
                {/* <div className='services__block'>
                <p className='form__h'>Exclusions</p>
                <p>Enter anything that you do not cover e.g models, service options </p>
                </div>
                { exclusions.map((tag,idx) => {
                const {name,label,data,value,id,onChange,placeholder,handleTagAdd,handleTagUpdate} = tag
                return <TagInput key={idx} 
                                value={value} 
                                id={id} 
                                data={data} 
                                label={label} 
                                handleTagUpdate={handleTagUpdate} 
                                name={name} 
                                placeholder={placeholder} 
                                handleTagAdd={handleTagAdd} 
                                handleOnChange={onChange} />
                })} */}
            </div>
            </form>
        </article>
        <ButtonContainer>
            <FormButton
            background='#76C3D4' 
            color='white' 
            border='none'
            onClick={next}>
                Next
            </FormButton>
            { error ? <p style={{ color: 'red'}}>{error}</p>: null }
        </ButtonContainer>
        <article className="info">
            <div className="info__features">
            {features.map(({icon,text,heading},idx)=>{
                return <InformativeFeature key={idx} icon={icon} text={text} heading={heading} />
            })}
            </div>
        </article>
    </div>
  )
}

export default Form