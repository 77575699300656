import React from 'react'
import styled from 'styled-components'

import '../../sass/views/policy.scss'
import Footer from '../../Components/footer/footer'

const StyledP = styled.p`
    margin-top: 0;
    margin-bottom: 5px;
    line-height: 1.8;
`

const BoldP = styled(StyledP)`
    font-weight: bold;
`

function TermsOfUse({ noFooter }) {
  return (
   <div>
    <div style={{ padding: '20px' }}>
<h1><strong>Terms and Conditions</strong></h1>

<h3><strong>1. Introduction</strong></h3>

<StyledP>Welcome to Tap A Service!</StyledP>

<StyledP>1.1. These terms and conditions outline the rules and regulations for the use of Tap A Service's Website, located at https://tapaservice.com</StyledP>

<StyledP>1.2. By accessing this website we assume you accept these terms and conditions. Do not continue to use Tap A Service if you do not agree to take all of the terms and conditions stated on this page.</StyledP>

<StyledP>1.3. The following terminology applies to these Terms and Conditions, Privacy Statement and Disclaimer Notice and all Agreements: "Client", "You" and "Your" refers to you, the person log on this website and compliant to the Company’s terms and conditions. "The Company", "Ourselves", "We", "Our" and "Us", refers to our Company. "Party", "Parties", or "Us", refers to both the Client and ourselves. All terms refer to the offer, acceptance and consideration of payment necessary to undertake the process of our assistance to the Client in the most appropriate manner for the express purpose of meeting the Client’s needs in respect of provision of the Company’s stated services, in accordance with and subject to, prevailing law of South Africa. Any use of the above terminology or other words in the singular, plural, capitalization and/or he/she or they, are taken as interchangeable and therefore as referring to same.</StyledP>
<StyledP>1.4. The Website enables you to access online Car Servicing Professionals and real time Professional Roadside Assistance ("Services")</StyledP>

<h3><strong>2. Consumer Protection Act (CPA)</strong></h3>
<StyledP>
2.1. These Terms and Conditions apply to users who are consumers for purposes of the Consumer Protection Act, 68 of 2008 (the “CPA”).
These Terms and Conditions contain provisions that appear in similar text and style to this clause and which: 
<ul>
    <li>2.1.1. may limit the risk or liability of Tap A Service; and/or</li>
    <li>2.1.2. may create risk or liability for the user; and/or</li>
    <li>2.1.3. may compel the user to indemnify Tap A Service; and/or</li>
    <li>2.1.4. serves as an acknowledgment, by the user, of a fact.</li>
</ul>
Your attention is drawn to these Terms and Conditions because they are important and should be carefully noted.
</StyledP>
<StyledP>
2.2. If there is any provision in these Terms and Conditions that you do not understand, it is your responsibility to ask Tap A Service to explain it to you before you accept the Terms and Conditions or continue using the Website.
Nothing in these Terms and Conditions is intended or must be understood to unlawfully restrict, limit or avoid any right or obligation, as the case may be, created for either you or Tap A Service in terms of the CPA.
Tap A Service permits the use of this Website subject to the Terms and Conditions. By using this Website in any way, you shall be deemed to have accepted all the Terms and Conditions unconditionally. You must not use this Website if you do not agree to the Terms and Conditions.
</StyledP>

<h3><strong>3. License</strong></h3>

<StyledP>3.1. Unless otherwise stated, Tap A Service and/or its licensors own the intellectual property rights for all material on Tap A Service. All intellectual property rights are reserved. You may access this from Tap A Service for your own personal use subjected to restrictions set in these terms and conditions.</StyledP>

<StyledP>You must not:
<ul>
    <li>3.1.1. Republish material from Tap A Service</li>
    <li>3.1.2. Sell, rent or sub-license material from Tap A Service</li>
    <li>3.1.3. Reproduce, duplicate or copy material from Tap A Service</li>
    <li>3.1.4. Redistribute content from Tap A Service</li>
</ul>
</StyledP>
<StyledP>3.2. This Agreement shall begin on the date hereof.</StyledP>

<StyledP>3.3. Parts of this website offer an opportunity for users to post and exchange opinions and information in certain areas of the website. Tap A Service does not filter, edit, publish or review Comments prior to their presence on the website. Comments do not reflect the views and opinions of Tap A Service,its agents and/or affiliates. Comments reflect the views and opinions of the person who post their views and opinions. To the extent permitted by applicable laws, Tap A Service shall not be liable for the Comments or for any liability, damages or expenses caused and/or suffered as a result of any use of and/or posting of and/or appearance of the Comments on this website.</StyledP>

<StyledP>3.4. Tap A Service reserves the right to monitor all Comments and to remove any Comments which can be considered inappropriate, offensive or causes breach of these Terms and Conditions.</StyledP>

<StyledP>3.5. You warrant and represent that:

<ul>
    <li>3.5.1. You are entitled to post the Comments on our website and have all necessary licenses and consents to do so;</li>
    <li>3.5.2. The Comments do not invade any intellectual property right, including without limitation copyright, patent or trademark of any third party;</li>
    <li>3.5.3. The Comments do not contain any defamatory, libelous, offensive, indecent or otherwise unlawful material which is an invasion of privacy</li>
    <li>3.5.4. The Comments will not be used to solicit or promote business or custom or present commercial activities or unlawful activity.</li>
</ul>
</StyledP>
<StyledP>3.6. You hereby grant Tap A Service a non-exclusive license to use, reproduce, edit and authorize others to use, reproduce and edit any of your Comments in any and all forms, formats or media.</StyledP>

<h3><strong>4. Hyperlinking to our Content</strong></h3>

<StyledP>4.1. The following organizations may link to our Website without prior written approval:

<ul>
    <li>4.1.1. Government agencies;</li>
    <li>4.1.2. Search engines;</li>
    <li>4.1.3. News organizations;</li>
    <li>4.1.4. Online directory distributors may link to our Website in the same manner as they hyperlink to the Websites of other listed businesses; and</li>
    <li>4.1.5. System wide Accredited Businesses except soliciting non-profit organizations, charity shopping malls, and charity fundraising groups which may not hyperlink to our Web site.</li>
</ul>
</StyledP>
<StyledP>4.2. These organizations may link to our home page, to publications or to other Website information so long as the link: (a) is not in any way deceptive; (b) does not falsely imply sponsorship, endorsement or approval of the linking party and its products and/or services; and (c) fits within the context of the linking party's site.</StyledP>

<StyledP>4.3. We may consider and approve other link requests from the following types of organizations:

<ul>
    <li>4.3.1. commonly-known consumer and/or business information sources;</li>
    <li>4.3.2. dot.com community sites;</li>
    <li>4.3.3. associations or other groups representing charities;</li>
    <li>4.3.4. online directory distributors;</li>
    <li>4.3.5. internet portals;</li>
    <li>4.3.6. accounting, law and consulting firms; and</li>
    <li>4.3.7. educational institutions and trade associations.</li>
</ul>
</StyledP>
<StyledP>4.4. We will approve link requests from these organizations if we decide that: (a) the link would not make us look unfavorably to ourselves or to our accredited businesses; (b) the organization does not have any negative records with us; (c) the benefit to us from the visibility of the hyperlink compensates the absence of Tap A Service; and (d) the link is in the context of general resource information.</StyledP>

<StyledP>4.5. These organizations may link to our home page so long as the link: (a) is not in any way deceptive; (b) does not falsely imply sponsorship, endorsement or approval of the linking party and its products or services; and (c) fits within the context of the linking party's site.</StyledP>

<StyledP>4.6. If you are one of the organizations listed in paragraph 2 above and are interested in linking to our website, you must inform us by sending an e-mail to Tap A Service. Please include your name, your organization name, contact information as well as the URL of your site, a list of any URLs from which you intend to link to our Website, and a list of the URLs on our site to which you would like to link. Wait 2-3 weeks for a response.</StyledP>

<StyledP>4.7. Approved organizations may hyperlink to our Website as follows:

<ul>
    <li>4.7.1. By use of our corporate name; or</li>
    <li>4.7.2. By use of the uniform resource locator being linked to; or</li>
    <li>4.7.3. By use of any other description of our Website being linked to that makes sense within the context and format of content on the linking party's site.</li>
</ul>
</StyledP>
<StyledP>4.8. No use of Tap A Service's logo or other artwork will be allowed for linking absent a trademark license agreement.</StyledP>

<h3><strong>5. Cookies</strong></h3>

<StyledP>5.1. We employ the use of cookies. By accessing Tap A Service, you agreed to use cookies in agreement with the Tap A Service's Privacy Policy.Most interactive websites use cookies to let us retrieve the user's details for each visit. 
    Cookies are used by our website to enable the functionality of certain areas to make it easier for people visiting our website. Some of our affiliate/advertising partners may also use cookies. For a detailed explanation please visit our <a style={{ color: 'blue' }} rel="noreferrer" target="_blank" href='https://tapaservice.com/cookie-policy'>Cookie Policy</a></StyledP>

    <h3><strong>6. Privacy</strong></h3>

<StyledP>6.1. We respect your privacy and will take all humanly possible measures to protect it for people visiting our website. For a detailed explanation please visit our <a style={{ color: 'blue' }} rel="noreferrer" target="_blank" href='https://tapaservice.com/privacy-policy'>Privacy Policy</a></StyledP>

<h3><strong>7. Registration and use of the Website</strong></h3>
<StyledP>7.1. Only registered users can offer and access Services on this Website. To offer Services on the website you need to create a Service Provider account using your unique email and password. To access Services on the website you need to login using social media login. You agree and warrant that your username and password shall:
    <ul>
        <li>7.1.1. be used for personal use only;</li>
    </ul>
    7.2. For security purposes you agree to enter the correct username and password whenever accessing Services, failing which you will be denied access.
    You agree that, once the correct username and password relating to your account have been entered, irrespective of whether the use of the username and password is unauthorised or fraudulent, you will be liable for payment of such a service, save where the service order is cancelled by you in accordance with these Terms and Conditions.
    You agree to notify Tap A Service immediately upon becoming aware of or reasonably suspecting any unauthorised access to or use of your username and password and to take steps to mitigate any resultant loss or harm.
</StyledP>
<StyledP>
    7.3. By using the Website you warrant that you are 18 (eighteen) years of age or older and of full legal capacity. If you are under the age of 18 (eighteen) or if you are not legally permitted to enter into a binding agreement, then you may use the Website only with the involvement and supervision of your parent or legal guardian. If your parent or legal guardian supervises you and gives his/her consent, then such person agrees to be bound to these Terms and Conditions and to be liable and responsible for you and all your obligations under these Terms and Conditions.
</StyledP>
<StyledP>
   7.4. You agree that you will not in any way use any device, software or other instrument to interfere or attempt to interfere with the proper working of the Website. In addition, you agree that you will not in any way use any robot, spider, other automatic device, or manual process to monitor, copy, distribute or modify the Website or the information contained herein, without the prior written consent from an authorised Company representative (such consent is deemed given for standard search engine technology employed by Internet search websites to direct Internet users to this Website).
</StyledP>
<StyledP>
   7.5. You may not use the Website to distribute material which is defamatory, offensive, contains or amounts to hate speech or is otherwise unlawful.
</StyledP>
<StyledP>
7.6. You may not in any way display, publish, copy, print, post or otherwise use the Website and/or the information contained therein without the express prior written consent of an authorised Company representative.
</StyledP>

<h3><strong>8. Suspension and Termination of Services</strong></h3>

<StyledP>8.1. You can terminate your Service Provider Account and delete your Subscription at any time on your Dashboard page. Such termination will result in the deactivation of your Profile and you won't be able to access it. This also deletes all the Content you collected through the use of Tap A Service. </StyledP>
<StyledP>8.2. Terminations are confirmed immediately and you will not be charged again for that Subscription unless you create a new one. If you terminate a Subscription in the middle of a Billing cycle you will not receive a refund, subscription will be canceled on the next payment date. You can only receive a refund if you are terminating for any of the following reasons:
<ul>
    <li>8.2.1. We have materially breached these Terms and failed to cure that breach within 30 days after you have notified us in writing;</li>
    <li>8.2.2. A refund is required by law;</li>
</ul>
</StyledP>
<StyledP>8.3. Tap A Service may terminate your Service Provider Account for any of the following reasons: 
<ul>
    <li>8.3.1. You have materially breached these Terms and failed to cure that breach within 30 days after we have notified you in writing;</li>
    <li>8.3.2. You fail to pay fees for 90 days past the due date;</li>
    <li>8.3.3. You fail to comply with these Terms;</li>
    <li>8.3.4. You use the Service in a way that causes legal liablility to us or disrupts others' use of the Service;</li>
</ul>
</StyledP>
<StyledP>8.4. Additionally Tap A Service may limit or suspend your Account if we are investigating suspected misconduct by you.</StyledP>
<StyledP>8.5. Also, if we limit suspend or terminate your Account, depending on the reason, we will endeavor to give you advance notice. However, there maybe ime sensitive situations where Tap A Service may decide that we need to take immediate action without notice.</StyledP>

<h3><strong>9. Towing Services Disclaimer</strong></h3>

<StyledP>9.1. Tap A Service is not responsible for any of the following during Roadside Assistance operations: 

<ul>
    <li>9.1.1. death or personal injury;</li>
    <li>9.1.2. damage to property;</li>
</ul>
</StyledP>
<StyledP>9.2. The Towing Company handling the Roadside Assistance operation and their respective Insurance Provider are responsible for anything that may go wrong during these operations.</StyledP>
<StyledP>9.3 When a client requests Towing Service they must enter their own address as the destination. Under No circumstances should the client use the Towing Company premises as the Towing destination address.</StyledP>
<StyledP>9.4 Should a client insist on using the Towing Company premises as the destination address we will not be held liable for storage costs incurred. Clients will use the Towing company premises as the destination at their own risk.</StyledP>

<h3><strong>10. Refund Policy</strong></h3>

<StyledP>10.1. We do not have a refund policy for Vehicle Servicing Bookings. As a client you should check out your vehicle once you have been satisfied with the service delivery. </StyledP>
<StyledP>10.2. Once a vehicle booking has been checked out it can not be reversed and as such Tap A Service will not be processing any refunds.</StyledP>

<StyledP>10.3. Client refunds for Roadside Assistance Services are only processed for incomplete requests that were accepted by the Tower and fully paid for by the client but could not be completed for whatever reason. We process client refunds for Roadside Assistance Services under these scenarios:
<ul>
<li>
10.3.1. If the tower has travelled a distance of more than 1 kilometre and the client chooses to cancel the request, the client will be charged for the distance travelled (using the tower's company towing rate) and refunded the balance. The distance travelled is determined by routing the tower's initial location coordinates and the tower's location coordinates at the time of the cancellation.
</li>
<li>
10.3.2. If the tower has not travelled a distance of more than 1 kilometer ( again, the distance travelled is determined by routing the tower's initial location coordinates and the tower's location coordinates at the time of the cancellation) and the client chooses to cancel then the client will be refunded their full amount.
</li>
<li>
10.3.3. If the tower chooses to cancel the request, the client is refunded their full amount, regardless whether the tower has travelled a distance of more than 1 kilometre or not
</li>
</ul>
</StyledP>
<StyledP>10.4. Refunds should be completed within 3-5 business days. If that period elapses and the client has not been refunded their money, the client can submit a query for that particular request on the app.</StyledP>

<h3><strong>11. Payments</strong></h3>
<StyledP>11.1. We are committed to providing secure online payment facilities.  
</StyledP>
<StyledP>
    11.2. All transactions are encrypted using appropriate encryption technology. All Payment Gateways used on our website are PCI-DSS Compliant.
</StyledP>
<StyledP>
    11.3. Payment may be made for either Car Servicing or Roadside Assistance via any of the available payment methods shown (depending on its availability and your eligibility to use such a method)
</StyledP>
<StyledP>
11.4. Where payment is made by debit card/credit card, we may require additional information in order to authorise and/or verify the validity of payment. We may withhold service availability until such time as the additional information is received by us and authorisation is obtained by us for the amounts. If we do not receive authorisation, you will not be rendered any services.
</StyledP>
<StyledP>
    11.5. You warrant that you are fully authorised to use the debit card/credit card supplied for purposes of paying for the Services, and that your debit card/credit card has sufficient available funds to cover all the costs incurred as a result of the services used on the Website
</StyledP>
<BoldP>
    11.6. Payouts to Service Providers will occur on Tuesdays of every week. 
</BoldP>
<StyledP>
11.7. You may contact us via email at info@tapaservice.com to obtain a full record of your payment. We will also send you email and SMS communications (where applicable) about your payment.
Once you have selected your payment method and you accept these Terms and Conditions, you will be directed to a link to a secure site for payment of the applicable price for the services.
</StyledP>


<h3><strong>12. Conflict Resolution</strong></h3>
<StyledP>
12.1. A dispute concerning this Agreement exists once a party notifies the others in writing of the nature of the dispute and requires it to be resolved under this clause.  The parties must refer any dispute to be resolved by:

<ul>
<li>12.1.1. negotiation; failing which</li>
<li>12.1.2. mediation; failing which</li>
<li>12.1.3. arbitration.</li>
</ul>
12.2. Within ten Business Days of notification, the parties must seek an amicable resolution to the dispute by referring it to designated and authorised representatives of each of the parties to negotiate and resolve it by the parties signing an agreement resolving it within 15 Business Days.
</StyledP>
<StyledP>
12.3. If negotiation fails, the parties must refer the dispute for resolution by mediation under the rules of the Arbitration Foundation of Southern Africa (or its successor or body nominated in writing by it in its stead) (AFSA).
</StyledP>
<StyledP>
12.4. If mediation fails, the parties must refer the dispute within 15 Business Days for resolution by arbitration (including any appeal against the arbitrator’s decision) by one arbitrator (appointed by agreement between the parties) as an expedited arbitration in Sandton under the then current rules for expedited arbitration of AFSA. 
</StyledP>
<StyledP>
12.5. If the parties cannot agree on any arbitrator within a period of ten Business Days after the referral, the arbitrator will be appointed by the Secretariat of AFSA.
</StyledP>
<StyledP>
12.6. The periods for negotiation or mediation may be shortened or lengthened by written agreement between the parties.
This clause will not preclude any party from access to an appropriate court of law for interim relief in respect of urgent matters by way of an interdict, or mandamus pending finalisation of this dispute resolution process, for which purpose the parties irrevocably submit to the jurisdiction of a division of the High Court of the Republic of South Africa.
This clause is a separate, divisible agreement from the rest of this Agreement and must remain in effect even if the Agreement terminates, is nullified, or cancelled for any reason or cause.
</StyledP>
<StyledP>
∗ AFSA is a non-profit organisation of longstanding and high integrity which provides independent and comprehensive administrative services in support of mediation and arbitration. The IoD recommends the choice of AFSA as a service provider to provide the parties with the maximum benefit from use of the dispute resolution clause. Should the Parties acting on the basis of informed consent wish to dispense with service providers or substitute others, then this clause will need to be redrafted. AFSA has joined with the University of Pretoria in issuing a diploma in Mediation and Arbitration. In doing so, numerous individuals have been trained as mediators and arbitrators. AFSA also has the most experienced panel of experts for effective alternative dispute resolution.
</StyledP>

<h3><strong>13. iFrames</strong></h3>

<StyledP>13.1. Without prior approval and written permission, you may not create frames around our Webpages that alter in any way the visual presentation or appearance of our Website.</StyledP>

<h3><strong>14. Content Liability</strong></h3>

<StyledP>14.1. We shall not be hold responsible for any content that appears on your Website. You agree to protect and defend us against all claims that is rising on your Website. No link(s) should appear on any Website that may be interpreted as libelous, obscene or criminal, or which infringes, otherwise violates, or advocates the infringement or other violation of, any third party rights.</StyledP>

<h3><strong>15. Reservation of Rights</strong></h3>

<StyledP>15.1. We reserve the right to request that you remove all links or any particular link to our Website. You approve to immediately remove all links to our Website upon request. We also reserve the right to amen these terms and conditions and it's linking policy at any time. By continuously linking to our Website, you agree to be bound to and follow these linking terms and conditions.</StyledP>

<h3><strong>16. Removal of links from our website</strong></h3>

<StyledP>16.1. If you find any link on our Website that is offensive for any reason, you are free to contact and inform us any moment. We will consider requests to remove links but we are not obligated to or so or to respond to you directly.</StyledP>

<StyledP>16.2. We do not ensure that the information on this website is correct, we do not warrant its completeness or accuracy; nor do we promise to ensure that the website remains available or that the material on the website is kept up to date.</StyledP>

<h3><strong>17. Errors</strong></h3>
<StyledP>
17.1. We shall take all reasonable efforts to accurately reflect the description, availability, service fee of Services on the Website. However, should there be any errors of whatsoever nature on the Website (which are not due to our gross negligence), we shall not be liable for any loss, claim or expense relating to a transaction based on any error, save – in the case of any incorrect purchase price – to the extent of refunding you for any amount already paid, or otherwise as set out in the Refunds Policy.
</StyledP>

<h3><strong>18. Disclaimer</strong></h3>

<StyledP>18.1. To the maximum extent permitted by applicable law, we exclude all representations, warranties and conditions relating to our website and the use of this website. Nothing in this disclaimer will:

<ul>
    <li>18.1.1. limit or exclude our or your liability for death or personal injury;</li>
    <li>18.1.2. limit or exclude our or your liability for fraud or fraudulent misrepresentation;</li>
    <li>18.1.3. limit any of our or your liabilities in any way that is not permitted under applicable law; or</li>
    <li>18.1.4. exclude any of our or your liabilities that may not be excluded under applicable law.</li>
</ul>
</StyledP>
<StyledP>18.2. The limitations and prohibitions of liability set in this Section and elsewhere in this disclaimer: (a) are subject to the preceding paragraph; and (b) govern all liabilities arising under the disclaimer, including liabilities arising in contract, in tort and for breach of statutory duty.</StyledP>

<StyledP>18.3. As long as the website and the information and services on the website are provided free of charge, we will not be liable for any loss or damage of any nature.</StyledP>
<StyledP>18.4. We are not responsible for the services provided by third-party garages, recovery vehicle companies, or other service providers listed on the platform. The agreement for services is strictly between the user and the third-party provider.</StyledP>
<StyledP>18.5. Users are responsible for ensuring the quality and suitability of the services provided by third-party providers. We make no representations or warranties regarding the competence or reliability of these providers.</StyledP>
<StyledP>18.6. We are not liable for any delays, cancellations, or issues arising from the booking of services or payment processing between users and third-party providers.</StyledP>
<StyledP>18.7. We do not accept liability for any damages or injuries incurred during the use of recovery or emergency services, as these are provided by independent third-party operators.</StyledP>
<StyledP>18.8. Where services are paid for through the platform, we shall not be liable for any indirect, incidental, or consequential damages related to the use of such services, except as required by applicable law.</StyledP>
<h3><strong>19. Changes to these terms and conditions</strong></h3>
<StyledP>
19.1. Tap A Service may, in its sole discretion, change any of these Terms and Conditions at any time. It is your responsibility to regularly check these Terms and Conditions and make sure that you are satisfied with the changes. Should you not be satisfied, you must not access Services on, or in any other way use, the Website.
</StyledP>
<StyledP>
19.2. Any such change will only apply to your use of this Website after the change is displayed on the Website. If you use the Website after such amended Terms and Conditions have been displayed on the Website, you will be deemed to have accepted such changes.
</StyledP>
   </div>
   { !noFooter ? <Footer />: null }
   </div>
  )
}

export default TermsOfUse