import preuploadSlice from '../Components/fileUploads/preupload.slice'
import serviceCreateSlice from '../Components/service/service.create.slice'
import uploadSlice from '../Components/fileUploads/upload.slice'
import modalSlice from '../Components/modal/modal.slice'
import servicesListSlice from '../Components/service/services.list.slice'
import serviceDetailSlice from '../Components/service/service.detail.slice'
import bookingsListSlice from '../Components/booking/bookings.list.slice'
import dashboardProfileSlice from '../Components/dashboard/redux/profile.detail.slice'
import metricsSlice from '../Components/dashboard/redux/metrics.slice'
import fcmPushSlice from '../Components/dashboard/redux/fcm.push.slice'
import towersListSlice from '../Components/tower/towers.list.slice'
import towerUpdateSlice from '../Components/tower/tower.update.slice'
import towerDeleteSlice from '../Components/tower/tower.delete.slice'
import submenuSlice from '../Components/navBar/submenu.slice'
import metadataSlice from '../Components/navBar/metadata.slice'
import towerCreateSlice from '../Components/tower/tower.create.slice'
import profileUpdateSlice from '../Components/dashboard/redux/profile.update.slice'
import progressSlice from '../Components/fileUploads/progress.slice'
import authSlice from '../Components/navBar/auth.slice'
import verificationCreateSlice from '../Components/dashboard/redux/verification.create.slice'
import emailLinkSlice from '../Components/dashboard/redux/email.link.slice'
import chatSlice from '../Components/chat/chat.slice'
import listSlice from '../Components/review/list.slice'
import { bookingDetailReducer } from '../Components/booking/booking.detail.slice'
import badgeSlice from '../Components/badge/badge.slice'
import profilesListSlice from '../Components/service/profiles.list.slice'
import profileDetailSlice from '../Components/service/profile.detail.slice'
import paymentsListSlice from '../Components/payment/payments.list.slice'
import { createReviewReducer } from '../Components/review/reviews.slice'
import bookingUpdateSlice from '../Components/booking/booking.update.slice'
import bookingProgressSlice from '../Components/booking/booking.progress.slice'
import towerCrudSlice from '../Components/tower/tower.crud.slice'
import profileDeleteSlice from '../Components/dashboard/redux/profile.delete.slice'
import accountDeleteSlice from '../Components/dashboard/redux/account.delete.slice'
import servicesSlice from '../Components/subscriptions/services.slice'
import subscriptionSlice from '../Components/subscriptions/subscription.slice'
import subscriptionDetailSlice from '../Components/dashboard/redux/subscription.detail.slice'
import subscriptionUpdateSlice from '../Components/dashboard/redux/subscription.update.slice'
import subscriptionRenewSlice from '../Components/dashboard/redux/subscription.renew.slice'
import { selectedCategoryReducer } from '../Components/service/services.list.slice'
import { subscriptionsReducer } from '../Components/subscriptions/services.slice'
import locationSlice from '../Components/list/location.slice'
import { profileTypeReducer } from '../Components/dashboard/redux/profile.detail.slice'
import { bookingAuthorisationsReducer } from '../Components/chat/chat.slice'

const rootReducer = {
    location: locationSlice,
    modal: modalSlice,
    preUpload: preuploadSlice,
    createProfile: serviceCreateSlice,
    uploadFiles: uploadSlice,
    profiles: servicesListSlice,
    roadsideProfiles: profilesListSlice,
    profileDetail: serviceDetailSlice,
    roadsideProfileDetail: profileDetailSlice,
    updateProfile: profileUpdateSlice,
    bookings: bookingsListSlice,
    dashboardProfile: dashboardProfileSlice,
    dashboardMetrics: metricsSlice,
    fcmPushToken: fcmPushSlice,
    towers: towersListSlice,
    towerUpdate: towerUpdateSlice,
    towerDelete: towerDeleteSlice,
    subMenu: submenuSlice,
    metadata: metadataSlice,
    createTowerProfile: towerCreateSlice,
    fileUploadsProgress: progressSlice,
    authCheck: authSlice,
    profileVerification: verificationCreateSlice,
    emailVerification: emailLinkSlice,
    sendMessage: chatSlice,
    reviews: listSlice,
    bookingDetail: bookingDetailReducer,
    unreadMessages: badgeSlice,
    payments: paymentsListSlice,
    createReview: createReviewReducer,
    bookingUpdate: bookingUpdateSlice,
    bookingProgress: bookingProgressSlice,
    towerCRUD: towerCrudSlice,
    createProfileDeletion: profileDeleteSlice,
    createAccountDeletion: accountDeleteSlice,
    servicesSubscription: servicesSlice,
    existingSubscription: subscriptionSlice,
    subscription: subscriptionDetailSlice,
    subscriptionUpdate: subscriptionUpdateSlice,
    renewSubscription: subscriptionRenewSlice,
    selectedCategory: selectedCategoryReducer,
    subscriptions: subscriptionsReducer,
    selectedProfileType: profileTypeReducer,
    bookingAuthorisations: bookingAuthorisationsReducer
}

export default rootReducer
